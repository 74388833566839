import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import Login from '../views/login/login.vue'
Vue.use(VueRouter)

const routes = [{
  path: '/login',
  name: 'login',
  component: Login,
  meta: {
    ignoreAuth: true
  }
}, {
  path: '/',
  // name: 'homeMap',
  // component: homeMap,
  name: 'home',
  component: Home,
  meta: {
    ignoreAuth: true
  }
}, {
  path: '/home',
  name: 'home',
  component: Home,
  meta: {
    ignoreAuth: true
  }
}]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.VUE_APP_DOMAIN,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('to', to)
  const token = store.state.token
  if (to.name === 'home') {
    if (to.query.qrcode) {
      store.commit('qrcode', to.query.qrcode)
      store.commit('zhcode', '')
    }
    if (to.query.zhcode) {
      store.commit('zhcode', to.query.zhcode)
      store.commit('qrcode', '')
    }
  }
  if (to.matched.some(r => r.meta.ignoreAuth) || token) {
    next()
  } else {
    next({
      path: '/login'
    })
  }
})

export default router
